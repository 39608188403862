import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'

import GetImgUrl from "../utils/customFunctions"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"

import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"

import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";

// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Reviews = loadable(() => import("../components/reviews/reviews"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const Accordion = loadable(() => import( "../components/accordion/accordion"))
// const CTAList = loadable(() => import( "../components/cta-list/ctaList"))
// const Providers = loadable(() => import(  "../components/provider-list/providers"))
// const Comparison = loadable(() => import(  "../components/comparison/comparison"))
// const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
// const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))




export const query = graphql`
  query primaryPagesQuery($id: Int) {
    strapiPages(strapiId: { eq: $id }) {
      Title
      PageContent
      SEOTitle
      SEODescription
      FooterDisclaimer
      MetaTags{
        MetaName
        MetaContent
      }
    }
  }
`;






// markup
const PrimaryPage = ({ data }) => {

    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        //   console.log(imgUrl)
        return (imgUrl)
    }



    if (data.strapiPages.PageContent) {

        return (

            <Layout SEOTitle={data.strapiPages.SEOTitle || data.strapiPages.Title} SEODescription={data.strapiPages.SEODescription} footerDisclaimer={data.strapiPages.FooterDisclaimer} meta={data.strapiPages.MetaTags}>


                {data.strapiPages.PageContent.map((item, index) => {


                    switch (item.strapi_component) {


                        case "page-components.image":
                            return (
                                <ImageCarousel key={index}


                                ></ImageCarousel>
                            )



                        case "page-components.header":
                            return (

                                <Header key={index}
                                    title={item.Title}
                                    markdownTitle={item.MarkdownTitle}
                                    subtitle={item.Subtitle}
                                    secondarySubtitle={item.SecondSubtitle}
                                    CTAText={item.CTALabel}
                                    CTAUrl={item.CTAUrl}
                                    CTA_nofollow={item.CTA_nofollow}
                                    Disclaimer={item.Disclaimer}
                                    Background={GetImgUrl(item.Background?.localFile___NODE)}
                                    HeaderHeight={item.HeaderHeight}
                                ></Header>

                            )

                        case "page-components.cta-banner":
                            return (

                                <CTABanner
                                    key={index}
                                    text={item.Text}
                                    ctaUrl={item.CTAUrl}
                                    ctaLabel={item.CTALabel}
                                    secondaryCtaUrl={item.SecondaryCTAUrl}
                                    secondaryCtaLabel={item.SecondaryCTALabel}
                                    bgColorHex={item.BackgroundColorHEX}
                                ></CTABanner>

                            )
                        case "page-components.image-slider":
                            var contentsList = []

                            item.Picture.map((image, index) => {

                                var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
                                contentsList.push({
                                    ALT: image.ALT,
                                    Image: imageUrl,
                                })
                                return ("")
                            })
                            return (

                                <ImageCarousel
                                    key={index}
                                    contents={contentsList}
                                ></ImageCarousel>
                            )

                        case "page-components.comparison":
                            return (

                                <Comparison key={index}
                                    title={item.Title}
                                    step1={item.Step1}
                                    step2={item.Step2}
                                    step3={item.Step3}
                                    image1={GetImgUrl(item.Image1.localFile___NODE)}
                                    image2={GetImgUrl(item.Image2.localFile___NODE)}
                                    image3={GetImgUrl(item.Image3.localFile___NODE)}
                                ></Comparison>


                            )


                        case "page-components.banner":
                            return (

                                <Banner
                                    key={index}
                                    text={item.Text}
                                    image={GetImgUrl(item.Image.localFile___NODE)}
                                    imageAlt={item.Image.alternativeText}
                                    dark={item.DarkBackground}
                                    title={item.Title}
                                    subtitle={item.Subtitle}
                                ></Banner>


                            )

                        case "page-components.paragraph":
                            return (

                                <Paragraph
                                    key={index}
                                    title={item.Title ? item.Title : ""}
                                    text={item.Text}
                                    bgColor={item.BackgroundColor || ""}

                                ></Paragraph>


                            )


                        case "page-components.cta-list":
                            var contentsList = []
                            item.CTAList.map((CTAitem, index) => {
                                var imageUrl = CTAitem.Image !== null ? GetImgUrl(CTAitem.Image.localFile___NODE) : ""
                                contentsList.push({
                                    Title: CTAitem.Title,
                                    URL: CTAitem.URL,
                                    URLLabel: CTAitem.URLLabel,
                                    Image: imageUrl,
                                    Alt: CTAitem.Image.alternativeText,
                                    Description: CTAitem.Description,
                                    DescriptionCharLimit: CTAitem.DescriptionCharLimit
                                })
                                return ("")
                            })
                            return (

                                <CTAList
                                    key={index}
                                    title={item.Title ? item.Title : ""}
                                    subtitle={item.Subtitle}
                                    contents={contentsList}
                                    cardStyle={item.CardStyle}
                                ></CTAList>


                            )

                        // case "page-components.html-editor":
                        //     return(

                        //             <HtmlEditor 
                        //             key={index}
                        //             htmlContent={item.HTML}
                        //             fullWidth={item.FullWidth}
                        //         ></HtmlEditor> 


                        //     )

                        default:
                            return ("")
                    }



                })}


            </Layout>
        )

    } else { return (<Layout>page empty. add some components.</Layout>) }

}

export default PrimaryPage